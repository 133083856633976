<template>
	<List :header="header" footer="当前版本V1.0.0" border>
		<ListItem>浏览器：{{userAgent}}</ListItem>
		<ListItem>系统平台信息：{{platform}}</ListItem>
		<ListItem>屏幕分辨率：{{screen}}</ListItem>
		<ListItem>登录人：{{username}}</ListItem>

	</List>
</template>

<script>
	export default {
		name: "Index",
		data() {
			return {
				header: '欢迎使用支付管理平台，当前时间：' + this.$dayjs().format('YYYY-MM-DD HH:mm:ss')

			}
		},

		created() {
			this.userAgent = navigator.userAgent
			this.platform = navigator.platform
			this.screen = screen.width + "x" + screen.height;
			this.username = JSON.parse(localStorage.getItem('info')).username

		},
		mounted() {
			let that = this
			setInterval(() => {
				that.header = '欢迎使用支付管理平台，当前时间：' + this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
			}, 1000)
		}
	}
</script>

<style>
</style>